<template>
  <div class="relative w-full font-Gilroy text-black">
    <Header />
    <div id="section-pronounciation" class="flex justify-center mt-10">
      <img
        :src="require('@/assets/images/marketing/texperia-pronounciation.png')"
        class="w-9/12"
      />
    </div>
    <div class="font-Poppins px-20 py-10 text-lg">
      We cracked the exact formula companies need to bridge the gap<br />between
      Human Resources or Employers and Employees! Texperia is<br />your partner
      in creating interactions that enhance employee<br />experience and build
      the ultimate team culture every healthy<br />
      organisation needs.<br /><br />

      Employee experiences in a company are not commonly treated as<br />problems
      that require solutions. So, if you've landed on this page and<br />are
      reading about us, Congratulations! You have taken the first step<br />towards
      creating a fun, healthy, interactive, and immersive work<br />culture.
    </div>
    <div id="section-core" class="flex pl-20 justify-start">
      <img
        :src="require('@/assets/images/marketing/about-core.png')"
        class="w-9/12"
      />
    </div>
    <div class="font-Poppins px-20 py-10 text-lg">
      The platform includes technologies such as Machine Learning,<br />Computer
      Vision, Augmented Reality, Immersive Web, Facial Tracking, Facial<br />
      Recognition, Skeletal Tracking, Gesture Recognition,<br />Motion Tracking,
      Real Time Video Processing, Emotion and Sentiment<br />Analysis and the
      soon to be launched Metaverse.
    </div>
    <div id="section-supported-by" class="p-20">
      <div
        class="text-mColorBlue font-GilroyBlack text-7xl tracking-wide leading-snug text-center"
      >
        Supported by
      </div>
      <p class="text-2xl text-center">We cannot do this alone. Thank you.</p>
      <div class="mt-10 flex justify-center gap-8">
        <img
          :src="require('@/assets/images/marketing/about-iim.png')"
          class="h-10"
        />
        <img
          :src="require('@/assets/images/marketing/about-razorpay.png')"
          class="h-10"
        />
        <img
          :src="require('@/assets/images/marketing/about-10000.png')"
          class="h-10"
        />
        <img
          :src="require('@/assets/images/marketing/about-slp.png')"
          class="h-10"
        />
      </div>
    </div>
    <div class="journey rounded-3xl relative p-10 pl-20">
      <img
        :src="require('@/assets/images/marketing/about-bubbles.png')"
        class="absolute -top-20 right-20 h-40"
      />
      <div class="mt-10 flex">
        <p class="texperia-underline tracking-widest font-Poppins">OUR JO</p>
        <p class="tracking-widest font-Poppins">URNEY</p>
      </div>
      <div
        class="text-mColorBlue font-GilroyBlack text-5xl tracking-wide leading-snug mt-6"
      >
        We've done this before!
      </div>
      <p class="font-Poppins font-semibold italic tracking-widest">
        Delivering digital customer experiences since 2016.
      </p>
      <div class="mt-6 font-Poppins tracking-wide leading-relaxed text-lg">
        Texperia is the result of a decade of experience in<br />creating
        memorable moments across film,<br />applications and immersive content.
        The founding<br />
        team has worked with brands, agencies and<br />enterprises across 9
        countries, and with over 900+<br />projects under our belt, we've turned
        our attention to<br />HR and team managers to help them significantly<br />enhance
        EX.<br /><br />

        Our mission is to deliver kick-ass results by<br />leveraging craft and
        technology. Texperia enables<br />every size and type of enterprise to
        design and<br />deliver their culturally unique employee experience<br />-
        quickly, and impactfully.
      </div>
    </div>
    <div id="section-team" class="px-20 pt-16">
      <div
        class="text-mColorBlue font-GilroyBlack text-5xl tracking-wide leading-snug mt-6"
      >
        Team Texperia
      </div>
      <div class="mt-6 flex justify-center gap-8 font-Poppins">
        <div>
          <img
            :src="
              require('@/assets/images/marketing/about-anirudh-goutham.png')
            "
            class="h-52"
          />
          <p class="text-lg mt-4">Anirudh Goutham</p>
          <p class="text-sm">Founder & CEO</p>
          <div class="text-sm mt-4 flex gap-3 items-center">
            <hr class="w-6 border-gray-500" />
            <a
              href="https://www.linkedin.com/in/anirudh-goutham/"
              target="_blank"
              ><img
                :src="require('@/assets/images/marketing/icon-linkedin.png')"
            /></a>
          </div>
        </div>
        <div>
          <img
            :src="require('@/assets/images/marketing/about-giridhar-j.png')"
            class="h-52"
          />
          <p class="text-lg mt-4">Giridhar J</p>
          <p class="text-sm">Founder & COO</p>
          <div class="text-sm mt-4 flex gap-3 items-center">
            <hr class="w-6 border-gray-500" />
            <a
              href="https://www.linkedin.com/in/giridhar-jayakumar/"
              target="_blank"
              ><img
                :src="require('@/assets/images/marketing/icon-linkedin.png')"
            /></a>
          </div>
        </div>
        <div>
          <img
            :src="require('@/assets/images/marketing/about-rohit-iyer.png')"
            class="h-52"
          />
          <p class="text-lg mt-4">Rohit Iyer</p>
          <p class="text-sm">CoFounder & CTO</p>
          <div class="text-sm mt-4 flex gap-3 items-center">
            <hr class="w-6 border-gray-500" />
            <a href="https://www.linkedin.com/in/iyerrohit/" target="_blank"
              ><img
                :src="require('@/assets/images/marketing/icon-linkedin.png')"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div id="section-advisors" class="px-20 pt-16">
      <div
        class="text-mColorBlue font-GilroyBlack text-5xl tracking-wide leading-snug mt-6"
      >
        Advisors and Mentors
      </div>
      <div class="mt-6 flex justify-center gap-20 font-Poppins">
        <div class="grid justify-items-center">
          <img
            :src="
              require('@/assets/images/marketing/about-dr-shivananda-koteshwar.png')
            "
            class="h-52"
          />
          <p class="text-lg h-8 mt-6">Dr. Shivananda Koteshwar</p>
          <p class="text-sm h-40 mt-6 w-64 text-justify">
            Philanthropist, Visiting Faculty and Adjunct Professor, Passionate
            teacher, Doctorate in Education Management, alumnus of IIM
            Bangalore/ MIT Sloan/ Mysore University/ Tuck University, Technology
            evangelist, Investor, Mentor, R&D Head at Synopsys Inc, Author and
            Actor.
          </p>
          <div class="text-sm mt-10 flex gap-3 items-center">
            <hr class="w-6 border-gray-500" />
            <a href="https://www.linkedin.com/in/shivoo2life/" target="_blank"
              ><img
                :src="require('@/assets/images/marketing/icon-linkedin.png')"
            /></a>
          </div>
        </div>
        <div class="grid justify-items-center gap-4">
          <img
            :src="require('@/assets/images/marketing/about-ramlal-waghray.png')"
            class="h-52"
          />
          <p class="text-lg h-8">Ramlal Waghray</p>
          <p class="text-sm h-50 w-64 text-justify">
            CEO, Predlytics, ViaMentor.com, Entrepreneur, Strategic Advisor,
            Mentor, Visiting Professor-Machine Learning/Business Analytics,
            Board Member, Association for Clinical Data Management.
          </p>
          <div class="text-sm mt-6 flex gap-3 items-center">
            <hr class="w-6 border-gray-500" />
            <a
              href="https://www.linkedin.com/in/ramlal-waghray-098837/"
              target="_blank"
              ><img
                :src="require('@/assets/images/marketing/icon-linkedin.png')"
            /></a>
          </div>
        </div>
        <div class="grid justify-items-center gap-4">
          <img
            :src="
              require('@/assets/images/marketing/about-raghavendra-prasad-ts.png')
            "
            class="h-52"
          />
          <p class="text-lg h-8">Raghavendra Prasad T S</p>
          <p class="text-sm h-50 w-64 text-justify">
            Serial Entrepreneur, Built Qikwell and Sold to Practo. Founder
            Project Step One, Problem Solver, Trend Spotter, Over a dozen
            International Patents, Avid traveler, A marathon runner, triathlete
            and a passionate biker.
          </p>
          <div class="text-sm mt-6 flex gap-3 items-center">
            <hr class="w-6 border-gray-500" />
            <a href="https://www.linkedin.com/in/tsrprasad/" target="_blank"
              ><img
                :src="require('@/assets/images/marketing/icon-linkedin.png')"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div id="banner-deliver-delight" class="p-20">
      <router-link
        :to="{
          name: 'Contact',
        }"
      >
        <img
          :src="require('@/assets/images/marketing/about-deliver-delight.png')"
          class="w-full"
        />
      </router-link>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Marketing/Desktop/Common/Header.vue";
import Footer from "@/components/Marketing/Desktop/Common/Footer.vue";

export default {
  name: "About",
  components: { Header, Footer },
  data() {
    return {};
  },
  created() {},
  updated() {},
  methods: {},
};
</script>
<style scoped>
.journey {
  background: #8bedbe;
  background-image: url("~@/assets/images/marketing/about-journey.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 75% 25%;
}
.texperia-underline {
  position: relative;
}
.texperia-underline::before {
  content: "";
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  height: 5px;
  border-radius: 2px;
  background: linear-gradient(
    45deg,
    #11bc1d 10%,
    #3d50e0 50%,
    #fdb400 75%,
    #ff5670 100%
  );
}
</style>
