<template>
  <button v-if="!loading" :class="`${buttonClass}`">
    {{ buttonText }}
  </button>

  <button v-else disabled :class="`${loadingClass}`">
    <svg
      class="animate-spin w-8 inline"
      version="1.1"
      id="loader-1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      style="enable-background: new 0 0 50 50"
      xml:space="preserve"
    >
      <path
        :fill="fillColor"
        d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
      ></path>
    </svg>
  </button>
</template>

<script>
export default {
  name: "ButtonInput",
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    buttonClass: {
      type: String,
      required: true,
    },
    loadingClass: {
      type: String,
      requried: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    fillColor: {
      type: String,
      required: false,
      default: () => "#FFFFFF",
    },
  },
};
</script>

<style scoped></style>
