<template>
  <div>
    <Desktop class="hidden md:block" />
    <Mobile class="block md:hidden" />
  </div>
</template>

<script>
import Desktop from "@/components/Marketing/Desktop/About.vue";
import Mobile from "@/components/Marketing/Mobile/About.vue";

export default {
  name: "About",
  components: { Desktop, Mobile },
  data() {
    return {};
  },
  created() {},
  updated() {},
  methods: {},
};
</script>
<style scoped></style>
