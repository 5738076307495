<template>
  <div class="relative w-full font-Gilroy text-black">
    <Header :headerBG="'white'" />
    <div id="section-banner" class="relative flex justify-center">
      <img
        :src="require('@/assets/images/marketing/mobile/banner-contact.png')"
        class="w-11/12"
      />
    </div>
    <div id="section-contact" class="px-6 pb-10">
      <div class="text-mColorBlue font-GilroyBlack text-4xl text-center">
        Drop a message
      </div>
      <p class="text-lg text-center mt-2">
        Our friendly team would love to hear from you.
      </p>
      <form
        id="form-demo"
        class="max-w-3xl mx-auto flex mt-4"
        @submit.prevent="submit()"
      >
        <div id="form-col-1" class="w-full font-Poppins text-lg">
          <div class="grid gap-4">
            <div class="f-outline w-full relative">
              <input
                required
                id="firstName"
                name="firstName"
                type="text"
                class="form-input"
                tabindex="1"
                placeholder=" "
                v-model="firstName"
              />
              <label for="firstName" class="form-floating-label"
                >First Name</label
              >
            </div>
            <div class="f-outline w-full relative">
              <input
                required
                id="lastName"
                name="lastName"
                type="text"
                class="form-input"
                tabindex="2"
                placeholder=" "
                v-model="lastName"
              />
              <label for="lastName" class="form-floating-label"
                >Last Name</label
              >
            </div>
          </div>
          <div class="grid gap-4 mt-4">
            <div class="f-outline w-full relative">
              <input
                required
                name="company"
                id="company"
                type="text"
                class="form-input"
                tabindex="3"
                placeholder=" "
                v-model="company"
              />
              <label for="company" class="form-floating-label"
                >Company Name</label
              >
            </div>
            <select
              class="form-select"
              v-model="numberOfEmployees"
              id="numberOfEmployees"
              required
              name="numberOfEmployees"
              tabindex="4"
            >
              <option value="" disabled selected>Number of Employees</option>
              <option value="1-100">1-100</option>
              <option value="101-250">101-250</option>
              <option value="251-500">251-500</option>
              <option value="501-1000">501-1000</option>
              <option value="1000+">1000+</option>
            </select>
          </div>
          <div class="grid gap-4 mt-4">
            <div class="f-outline w-full relative">
              <input
                required
                v-model="email"
                id="email"
                type="email"
                name="email"
                class="form-input"
                tabindex="5"
                placeholder=" "
              />
              <label for="email" class="form-floating-label"
                >Email Address</label
              >
            </div>
            <div class="f-outline w-full relative">
              <input
                required
                name="mobileNumber"
                id="mobileNumber"
                type="text"
                class="form-input"
                tabindex="6"
                placeholder=" "
                v-model="mobileNumber"
              />
              <label for="mobileNumber" class="form-floating-label"
                >Mobile Number</label
              >
            </div>
          </div>
          <div class="grid gap-4 mt-4">
            <div class="f-outline w-full relative">
              <input
                required
                v-model="message"
                id="message"
                type="text"
                name="message"
                class="form-input"
                tabindex="7"
                placeholder=" "
              />
              <label for="message" class="form-floating-label">Message</label>
            </div>
          </div>
          <div class="grid items-center gap-4 mt-8">
            <div class="flex w-full">
              <ButtonInput
                :loading="loading"
                buttonText="submit"
                buttonClass="font-Gilroy font-bold buttonClass w-full px-8 py-3 rounded-xl uppercase text-white text-sm"
                loadingClass="font-Gilroy font-bold buttonClass w-full px-8 py-3 rounded-xl uppercase text-white text-sm cursor-disabled"
              />
            </div>
            <div class="h-6">
              <p v-if="successMsg" class="text-green-700 text-sm font-medium">
                {{ successMsg }}
              </p>
              <p v-if="errorMsg" class="text-red-700 text-sm font-medium">
                {{ errorMsg }}
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
    <Footer />
  </div>
</template>

<script>
import { apiResource } from "@/common/app.config";
import Header from "@/components/Marketing/Mobile/Common/Header.vue";
import Footer from "@/components/Marketing/Mobile/Common/Footer.vue";
import ButtonInput from "@/components/Elements/ButtonInput.vue";

export default {
  name: "Contact",
  components: { Header, Footer, ButtonInput },
  data() {
    return {
      firstName: "",
      lastName: "",
      company: "",
      numberOfEmployees: "",
      email: "",
      mobileNumber: "",
      message: "",
      errorMsg: null,
      successMsg: null,
      loading: false,
    };
  },
  created() {},
  updated() {},
  methods: {
    submit() {
      this.loading = true;
      const formBody = {
        first_name: this.firstName,
        last_name: this.lastName,
        company: this.company,
        no_of_employees: this.numberOfEmployees,
        work_email: this.email,
        mobile_number: this.mobileNumber,
        message: this.message,
      };
      fetch(apiResource.marketing.bookADemo, {
        method: "POST",
        body: JSON.stringify(formBody),
        headers: { "Content-type": "application/json; charset=UTF-8" },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.status) {
            this.errorMsg = null;
            this.successMsg = data.msg;
            this.firstName = "";
            this.lastName = "";
            this.company = "";
            this.numberOfEmployees = "";
            this.email = "";
            this.mobileNumber = "";
            this.message = "";
          } else {
            let msg = data.msg[Object.keys(data.msg)[0]];
            this.errorMsg = msg;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="postcss" scoped>
.form-input {
  @apply bg-white text-lg text-black pt-3 pb-4 border-b border-gray-500 block appearance-none bg-transparent w-full font-Poppins;
}
.form-input:focus {
  @apply outline-none border-b border-blue-500;
}
.form-floating-label {
  @apply absolute top-0 text-lg text-gray-400 bg-white mt-4 duration-300 cursor-text ml-1;
}
.form-select {
  @apply bg-white text-lg text-black pt-3 pb-1 border-b border-gray-500 block bg-transparent w-full font-Poppins pl-0;
}
.form-select:focus {
  @apply outline-none border-b border-blue-500;
}
.form-select:required:invalid {
  @apply text-lg text-gray-400 font-Poppins;
}
.form-select > option[value=""][disabled] {
  display: none;
}
.form-select > option {
  color: #000;
}
.f-outline input:focus-within ~ label,
.f-outline input:not(:placeholder-shown) ~ label {
  @apply font-semibold;
  transform: translateY(-1.6rem) translatex(-0.5rem) scaleX(0.8) scaleY(0.8);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
.buttonClass {
  background: #231f20;
}
</style>
