<template>
  <div class="relative w-full font-Gilroy text-black">
    <Header />
    <div id="section-pronounciation" class="flex justify-center">
      <img
        :src="
          require('@/assets/images/marketing/mobile/texperia-pronounciation.png')
        "
        class="w-10/12"
      />
    </div>
    <div class="font-Poppins px-6 py-4 text-base">
      We cracked the exact formula companies need to bridge the gap between
      Human Resources or Employers and Employees! Texperia is your partner in
      creating interactions that enhance employee experience and build the
      ultimate team culture every healthy organisation needs.<br /><br />

      Employee experiences in a company are not commonly treated as problems
      that require solutions. So, if you've landed on this page and are reading
      about us, Congratulations! You have taken the first step towards creating
      a fun, healthy, interactive, and immersive work culture.
    </div>
    <div id="section-core" class="flex px-6 justify-center">
      <img
        :src="require('@/assets/images/marketing/mobile/about-core.png')"
        class="w-full"
      />
    </div>
    <div class="font-Poppins px-6 py-4 text-base">
      The platform includes technologies such as Machine Learning, Computer
      Vision, Augmented Reality, Immersive Web, Facial Tracking, Facial
      Recognition, Skeletal Tracking, Gesture Recognition, Motion Tracking, Real
      Time Video Processing, Emotion and Sentiment Analysis and the soon to be
      launched Metaverse.
    </div>
    <div id="section-supported-by" class="p-6">
      <div
        class="text-mColorBlue font-GilroyBlack text-4xl tracking-wide leading-snug text-center"
      >
        Supported by
      </div>
      <p class="text-lg text-center">We cannot do this alone. Thank you.</p>
      <div class="mt-10 flex justify-center gap-4">
        <img
          :src="require('@/assets/images/marketing/about-iim.png')"
          class="h-5"
        />
        <img
          :src="require('@/assets/images/marketing/about-razorpay.png')"
          class="h-5"
        />
        <img
          :src="require('@/assets/images/marketing/about-10000.png')"
          class="h-5"
        />
        <img
          :src="require('@/assets/images/marketing/about-slp.png')"
          class="h-5"
        />
      </div>
    </div>
    <div class="journey rounded-3xl relative p-4 mt-10">
      <img
        :src="require('@/assets/images/marketing/about-bubbles.png')"
        class="absolute -top-10 right-20 h-20"
      />
      <div class="p-2 flex">
        <p class="texperia-underline tracking-widest font-Poppins">OUR JO</p>
        <p class="tracking-widest font-Poppins">URNEY</p>
      </div>
      <div class="flex justify-center">
        <img
          :src="require('@/assets/images/marketing/about-journey.png')"
          class="h-60"
        />
      </div>
      <div
        class="text-mColorBlue font-GilroyBlack text-2xl tracking-wide leading-snug mt-6"
      >
        We've done this before!
      </div>
      <p class="font-Poppins font-semibold italic tracking-widest text-base">
        Delivering digital customer experiences since 2016.
      </p>
      <div class="mt-6 font-Poppins tracking-wide leading-relaxed text-base">
        Texperia is the result of a decade of experience in creating memorable
        moments across film, applications and immersive content. The founding
        team has worked with brands, agencies and enterprises across 9
        countries, and with over 900+ projects under our belt, we've turned our
        attention to HR and team managers to help them significantly enhance
        EX.<br /><br />

        Our mission is to deliver kick-ass results by leveraging craft and
        technology. Texperia enables every size and type of enterprise to design
        and deliver their culturally unique employee experience - quickly, and
        impactfully.
      </div>
    </div>
    <div id="section-team" class="px-6 pt-6">
      <div class="text-mColorBlue font-GilroyBlack text-4xl text-center mt-6">
        Team Texperia
      </div>
      <div class="mt-6 grid justify-center gap-8 font-Poppins">
        <div class="grid justify-items-center">
          <img
            :src="
              require('@/assets/images/marketing/about-anirudh-goutham.png')
            "
            class="h-52"
          />
          <p class="text-xl mt-4">Anirudh Goutham</p>
          <p class="text-base">Founder & CEO</p>
          <a
            href="https://www.linkedin.com/in/anirudh-goutham/"
            target="_blank"
            class="mt-2"
            ><img :src="require('@/assets/images/marketing/icon-linkedin.png')"
          /></a>
        </div>
        <div class="grid justify-items-center">
          <img
            :src="require('@/assets/images/marketing/about-giridhar-j.png')"
            class="h-52"
          />
          <p class="text-xl mt-4">Giridhar J</p>
          <p class="text-base">Founder & COO</p>
          <a
            href="https://www.linkedin.com/in/giridhar-jayakumar/"
            target="_blank"
            class="mt-2"
            ><img :src="require('@/assets/images/marketing/icon-linkedin.png')"
          /></a>
        </div>
        <div class="grid justify-items-center">
          <img
            :src="require('@/assets/images/marketing/about-rohit-iyer.png')"
            class="h-52"
          />
          <p class="text-xl mt-4">Rohit Iyer</p>
          <p class="text-base">CoFounder & CTO</p>
          <a
            href="https://www.linkedin.com/in/iyerrohit/"
            target="_blank"
            class="mt-2"
            ><img :src="require('@/assets/images/marketing/icon-linkedin.png')"
          /></a>
        </div>
      </div>
    </div>
    <div id="section-advisors" class="px-6 pt-6">
      <div class="text-mColorBlue font-GilroyBlack text-4xl text-center mt-6">
        Advisors and Mentors
      </div>
      <div class="mt-6 grid justify-center gap-8 font-Poppins">
        <div class="grid justify-items-center">
          <img
            :src="
              require('@/assets/images/marketing/about-dr-shivananda-koteshwar.png')
            "
            class="h-52"
          />
          <p class="text-xl mt-4">Dr. Shivananda Koteshwar</p>
          <p class="text-base mt-4">
            Philanthropist, Visiting Faculty and Adjunct Professor, Passionate
            teacher, Doctorate in Education Management, alumnus of IIM
            Bangalore/ MIT Sloan/ Mysore University/ Tuck University, Technology
            evangelist, Investor, Mentor, R&D Head at Synopsys Inc, Author and
            Actor.
          </p>
          <a
            href="https://www.linkedin.com/in/shivoo2life/"
            target="_blank"
            class="mt-2"
            ><img :src="require('@/assets/images/marketing/icon-linkedin.png')"
          /></a>
        </div>
        <div class="grid justify-items-center">
          <img
            :src="require('@/assets/images/marketing/about-ramlal-waghray.png')"
            class="h-52"
          />
          <p class="text-xl mt-4">Ramlal Waghray</p>
          <p class="text-base mt-4">
            CEO, Predlytics, ViaMentor.com, Entrepreneur, Strategic Advisor,
            Mentor, Visiting Professor-Machine Learning/Business Analytics,
            Board Member, Association for Clinical Data Management.
          </p>
          <a
            href="https://www.linkedin.com/in/ramlal-waghray-098837/"
            target="_blank"
            class="mt-2"
            ><img :src="require('@/assets/images/marketing/icon-linkedin.png')"
          /></a>
        </div>
        <div class="grid justify-items-center">
          <img
            :src="
              require('@/assets/images/marketing/about-raghavendra-prasad-ts.png')
            "
            class="h-52"
          />
          <p class="text-xl mt-4">Raghavendra Prasad T S</p>
          <p class="text-base mt-4">
            Serial Entrepreneur, Built Qikwell and Sold to Practo. Founder
            Project Step One, Problem Solver, Trend Spotter, Over a dozen
            International Patents, Avid traveler, A marathon runner, triathlete
            and a passionate biker.
          </p>
          <a
            href="https://www.linkedin.com/in/tsrprasad/"
            target="_blank"
            class="mt-2"
            ><img :src="require('@/assets/images/marketing/icon-linkedin.png')"
          /></a>
        </div>
      </div>
    </div>
    <div id="banner-deliver-delight" class="p-6 mt-6">
      <router-link
        :to="{
          name: 'Contact',
        }"
      >
        <img
          :src="
            require('@/assets/images/marketing/mobile/about-deliver-delight.png')
          "
          class="w-full"
        />
      </router-link>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Marketing/Mobile/Common/Header.vue";
import Footer from "@/components/Marketing/Mobile/Common/Footer.vue";

export default {
  name: "About",
  components: { Header, Footer },
  data() {
    return {};
  },
  created() {},
  updated() {},
  methods: {},
};
</script>
<style scoped>
.journey {
  background: #8bedbe;
}
.texperia-underline {
  position: relative;
}
.texperia-underline::before {
  content: "";
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  height: 5px;
  border-radius: 2px;
  background: linear-gradient(
    45deg,
    #11bc1d 10%,
    #3d50e0 50%,
    #fdb400 75%,
    #ff5670 100%
  );
}
</style>
